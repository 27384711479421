import api from "!../.yarn/$$virtual/style-loader-virtual-d3317814ac/0/cache/style-loader-npm-2.0.0-b9a5c4a2aa-ffc3054882.zip/node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
            import content from "!!../.yarn/$$virtual/css-loader-virtual-75bb9a4afc/0/cache/css-loader-npm-5.2.6-118c6d409e-b9e5a32246.zip/node_modules/css-loader/dist/cjs.js!../.yarn/$$virtual/sass-loader-virtual-7c1af2722b/0/cache/sass-loader-npm-12.0.0-3912d64a12-c873781a27.zip/node_modules/sass-loader/dist/cjs.js!./index.scss";

var options = {};

options.insert = "head";
options.singleton = false;

var update = api(content, options);



export default content.locals || {};