import React from "react";
import logo from '../assets/coloniergames_logo_only.png';

export default class Header extends React.Component {
    render() {
        return <section className="hero is-primary is-medium">
            <div className="hero-body coloniergames-header">
                <div className="container has-text-centered">
                    <p className="title">
                        Colonier.Dev
                    </p>
                    <p className="subtitle">
                        By developers of developers for developers
                    </p>
                </div>
            </div>
        </section>
    }
}
