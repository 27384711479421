import React from 'react';
import ReactDOM from 'react-dom';
import "@fontsource/roboto";
import './index.scss';
import Header from './components/header';
import Body from './components/body';
import Container from "./components/base/container";
import Footer from "./components/footer";

ReactDOM.render(
  <React.StrictMode>
      <Header></Header>
      <Container fluid className="body pt-6 pb-6" style={{boxShadow: "inset 0 -5px 10px -10px rgba(0,0,0,0.6)"}}>
          <Body></Body>
      </Container>
      <Footer></Footer>
  </React.StrictMode>,
  document.getElementById('app')
);
