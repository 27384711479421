import React from "react";

export default class Body extends React.Component {
    render() {
        return <div className="tile is-ancestor">
                    <div className="tile is-8 is-parent is-vertical">
                        <article className="tile is-child box">
                            <p className="title">Placeholder title</p>
                            <div className="content">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In euismod velit ultrices neque lacinia interdum. Fusce ac lacus euismod, commodo erat quis, accumsan lorem. Nulla ultricies nunc quis nibh venenatis maximus. Suspendisse hendrerit quis arcu a pulvinar. Integer feugiat, odio vel semper scelerisque, risus velit volutpat lacus, vel placerat neque sapien vitae metus. Integer quis rutrum arcu, nec imperdiet purus. Morbi enim turpis, aliquam vel lacus ac, cursus aliquet ipsum. In sit amet purus eu neque ullamcorper vestibulum vitae vel nisi. Aliquam finibus dapibus nisl, vehicula aliquet tellus vehicula id. Mauris lacinia posuere gravida. Nullam pulvinar eget velit at rhoncus. Sed bibendum ut nibh quis pretium. Cras fringilla rhoncus velit, id porta magna auctor nec. Praesent eu odio sagittis, efficitur mauris non, placerat tortor.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In euismod velit ultrices neque lacinia interdum. Fusce ac lacus euismod, commodo erat quis, accumsan lorem. Nulla ultricies nunc quis nibh venenatis maximus. Suspendisse hendrerit quis arcu a pulvinar. Integer feugiat, odio vel semper scelerisque, risus velit volutpat lacus, vel placerat neque sapien vitae metus. Integer quis rutrum arcu, nec imperdiet purus. Morbi enim turpis, aliquam vel lacus ac, cursus aliquet ipsum. In sit amet purus eu neque ullamcorper vestibulum vitae vel nisi. Aliquam finibus dapibus nisl, vehicula aliquet tellus vehicula id. Mauris lacinia posuere gravida. Nullam pulvinar eget velit at rhoncus. Sed bibendum ut nibh quis pretium. Cras fringilla rhoncus velit, id porta magna auctor nec. Praesent eu odio sagittis, efficitur mauris non, placerat tortor.
                            </div>
                        </article>

                        <article className="tile is-child box">
                            <p className="title">Placeholder title</p>
                            <div className="content">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In euismod velit ultrices neque
                                lacinia interdum. Fusce ac lacus euismod, commodo erat quis, accumsan lorem. Nulla
                                ultricies nunc quis nibh venenatis maximus. Suspendisse hendrerit quis arcu a pulvinar.
                                Integer feugiat, odio vel semper scelerisque, risus velit volutpat lacus, vel placerat
                                neque sapien vitae metus. Integer quis rutrum arcu, nec imperdiet purus. Morbi enim
                                turpis, aliquam vel lacus ac, cursus aliquet ipsum. In sit amet purus eu neque
                                ullamcorper vestibulum vitae vel nisi. Aliquam finibus dapibus nisl, vehicula aliquet
                                tellus vehicula id. Mauris lacinia posuere gravida. Nullam pulvinar eget velit at
                                rhoncus. Sed bibendum ut nibh quis pretium. Cras fringilla rhoncus velit, id porta magna
                                auctor nec. Praesent eu odio sagittis, efficitur mauris non, placerat tortor.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In euismod velit ultrices neque
                                lacinia interdum. Fusce ac lacus euismod, commodo erat quis, accumsan lorem. Nulla
                                ultricies nunc quis nibh venenatis maximus. Suspendisse hendrerit quis arcu a pulvinar.
                                Integer feugiat, odio vel semper scelerisque, risus velit volutpat lacus, vel placerat
                                neque sapien vitae metus. Integer quis rutrum arcu, nec imperdiet purus. Morbi enim
                                turpis, aliquam vel lacus ac, cursus aliquet ipsum. In sit amet purus eu neque
                                ullamcorper vestibulum vitae vel nisi. Aliquam finibus dapibus nisl, vehicula aliquet
                                tellus vehicula id. Mauris lacinia posuere gravida. Nullam pulvinar eget velit at
                                rhoncus. Sed bibendum ut nibh quis pretium. Cras fringilla rhoncus velit, id porta magna
                                auctor nec. Praesent eu odio sagittis, efficitur mauris non, placerat tortor.
                            </div>
                        </article>

                    </div>
                    <div className="tile is-parent is-vertical">
                        <article className="tile is-child box">
                            <p className="title">Kategóriák</p>
                        </article>
                    </div>
                </div>
    }
}
